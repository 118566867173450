<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="
            container
            shape-container
            d-flex
            align-items-center
            text-center-sm
          "
        >
          <h1 class="text-dark display-1 f-bold text-head mb-3">Our Blog</h1>
          <p class="f-16 text-dark mb-0">
            Keep up with the latest renewable energy news, trends and product
            reviews
          </p>
          <div>
            <a :href="blogs[0].url" target="_blank">
              <div
                class="featuredblog"
                :style="`background-image: url(${blogs[0].image})`"
              >
                <div class="blogData">
                  <div class="row">
                    <div class="col-md-8">
                      <h3>
                        {{ blogs[0].title }}
                      </h3>
                    </div>
                    <div class="col-md-4">
                      <img
                        height="20"
                        class="mr-2"
                        src="/assets/images/svgs/imp-icon.svg"
                        alt=""
                      />
                      <span class="text-white">By Sterling</span>
                    </div>
                  </div>
                </div>
              </div></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pb-2 mb-7">
      <div class="container">
        <div class="evaluation">
          <h2 class="text-primary f-bold">Top Articles</h2>
          <div class="mt-5">
            <div class="row">
              <div class="col-md-4" v-for="(blog, index) in blogs" :key="index">
                <BlogCard :blog="blog" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from "@/components/Blog/BlogCard";
export default {
  name: "Calculator",
  components: { BlogCard },
  data() {
    return {
      calculation: "general",
      blogs: [
        {
          title: "Even on mothers day too",
          description:
            "A whole Mother’s day and to call my Mama na wahala. One whole week and no light and you’ll at least believe that with the increased price per barrel of oil in the international market, pump price will be cheaper but NO!",
          image:
            "https://nairametrics.com/wp-content/uploads/2020/07/Fuel-2.jpg",
          url: "https://sterling.ng/even-on-mothers-day-too/",
        },
        {
          title: "Something just happened now",
          description:
            "I was talking to Yinka one Tuesday morning and I told her that I had to leave early because the Third Mainland Bridge was partially closed for repairs and she asked me, ‘What’s that?’",
          image:
            "https://sterling.ng/wp-content/uploads/2020/08/Nnennas-articles-without.jpg",
          url: "https://sterling.ng/something-just-happened-now/",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.calHeading {
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 32.256px;
    color: #2b2b2b;
    display: inline-block;
    margin-bottom: 0px;
  }
}
.basedClass {
  background: #e0ecce;
  border-radius: 19px;
  display: inline-block;
  div {
    display: inline-block;
    font-weight: 500;
    font-size: 14.9652px;
    color: #255e13;
    padding: 7px 32px;
    line-height: 51px;
    cursor: pointer;
    &.active {
      background-color: #219653;
      border-radius: 19px;
      color: white;
    }
  }
}
.appliance {
  color: black;
  background: #0000000a;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 31px;
    padding: 1px 7px;
    cursor: pointer;
  }
  .name {
    margin: 0;
    font-weight: bold;
  }
  .descr {
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
  }
}
.bg-prp {
  background: #f5fcf2;
}
@media only screen and (max-width: 768px) {
  .headerimg {
    width: 72%;
    padding-top: 60px;
  }
  .basedClass {
    margin-top: 20px;
  }
  .formView {
    margin-top: 0px !important;
    background: transparent;
  }
  .maintenn {
    margin-top: 45px;
  }
  .yourCal {
    padding: 0px;
    border: none;
  }
}
.evaluation .evaluateMedia p {
  font-weight: 300;
  font-size: 18px;
  color: #202020;
}
.featuredblog {
  height: 356px;
  border-radius: 25px;
  margin-top: 42px;
  padding: 25px;
  position: relative;
  background-size: cover !important;
  background-position: center !important;
  div.blogData {
    position: absolute;
    bottom: 25px;
    width: 100%;
    h3 {
      font-weight: bold;
      color: white;
    }
  }
}
</style>